import React, { Component, ReactNode } from 'react';
import { Settings, CoreModule, ExecuteRule } from './Modules';
import { IBaseModuleProps } from '@wings/shared';
import { AuthStore, SettingsModuleSecurity } from '@wings-shared/security';
import { Routes, Route } from 'react-router-dom';
import { inject } from 'mobx-react';
import { PermitModuleSecurity, sidebarOptions } from './Modules/Shared';
import { PermitRoutes } from './Modules/Core/Components';
import { NotFoundPage, SearchStore } from '@wings-shared/core';
import { ProtectedRoute } from '@wings-shared/layout';

@inject('sidebarStore')
export default class PermitsApp extends Component<IBaseModuleProps> {
  private readonly redirectPath: string = '/permits';
  constructor(props) {
    super(props);
    this.props.sidebarStore?.setNavLinks(sidebarOptions(false), this.props.basePath);
    // Needs to set permissions after lazy loading
    PermitModuleSecurity.init();
    SettingsModuleSecurity.updatePermissions();
    AuthStore.configureAgGrid();
  }

  // 81904 clear search store on application unmount
  componentWillUnmount(): void {
    SearchStore.clearSearch();
  }

  public render(): ReactNode {
    return (
      <Routes>
        <Route path="permits/*">
          <Route index element={<CoreModule />} />
          <Route path="settings" element={<Settings />} />
          <Route path="executeRules" element={<ExecuteRule />} />
          <Route
            path=":viewMode/*"
            key="permitAdd"
            element={
              <ProtectedRoute
                element={<PermitRoutes key={'permit-add'}/>}
                redirectPath={this.redirectPath}
                hasPermission={PermitModuleSecurity.isEditable}
              />
            }
          />
          <Route path=":permitId/:viewMode/*" element={<PermitRoutes key={'permit-edit'} />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    );
  }
}

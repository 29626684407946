export const apiUrls = {
  permit: 'api/Permit',
  permitExecuteRule: 'api/Permit/Execute',
  permitExecuteRuleV2: 'api/Permit/v2/Execute',
  country: 'api/Country',
  fir: 'api/FIR',
  accessLevel: 'api/AccessLevel',
  sourceType: 'api/SourceType',
  permitAppliedTo: 'api/PermitAppliedTo',
  permitType: 'api/PermitType',
  farType: 'api/FARType',
  referenceData: 'api/referenceData',
  ruleConditionalOperator: 'api/RuleConditionalOperator',
  purposeOfFlight: 'api/PurposeOfFlight',
  ruleEntity: 'api/ruleEntityType',
  ruleEntityParameterConfig: 'api/RuleEntityParameterConfig',
  noiseChapter: 'api/NoiseChapter',
  region: 'api/Region',
  aerodromeReferenceCode: 'api/ICAOAerodromeReferenceCode',
  aircraftCategory: 'api/Category',
  permitRequirementType: 'api/PermitRequirementType',
  flightOperationalCategory: 'api/FlightOperationalCategory',
  timeLevelUOM: 'api/TimeLevelUOM',
  leadTimeType: 'api/LeadTimeType',
  permitValidity: 'api/PermitValidity',
  permitPrerequisiteType: 'api/PermitPrerequisiteType',
  blanketValidityType: 'api/BlanketValidityType',
  permitDiplomaticType: 'api/PermitDiplomaticType',
  permitNumberException: 'api/PermitNumberExceptionType',
  element: 'api/Element',
  document: 'api/Document',
  missionElement: 'api/MissionElement',
  dataElement: 'api/DataElement',
  crossingType: 'api/CrossingType',
  airportOfEntry: 'api/AirportOfEntry',
  permitClassification: 'api/PermitClassification',
  permitDMNote: (id: number) => `api/permit/${id}/PermitDMNote`,
  presetValidity: 'api/PresetValidity',
};

import React, { ReactNode, useState } from 'react';
import { UpsertSettings } from '@wings/shared';
import { inject, observer } from 'mobx-react';
import { regex, SelectOption } from '@wings-shared/core';
import { categoryList, settingList } from './Fields';
import { PermitSettingsStore, SETTING_ID } from '../Shared';
import { Document, FARType } from './Components';
import { SettingsLayout } from '@wings-shared/layout';

interface Props {
  permitSettingsStore?: PermitSettingsStore;
}

const Settings: React.FC<Props> = ({ permitSettingsStore }) => {
  const [ activeCategory, setActiveCategory ] = useState<number>(1);
  const [ activeSubCategory, setActiveSubCategory ] = useState<number>(1);
  const _permitSettingsStore = permitSettingsStore as PermitSettingsStore;

  const subCategories = (category?: number): SelectOption[] => {
    return settingList
      .filter(setting => setting.categoryId === (category || activeCategory))
      .map(setting => new SelectOption({ name: setting.settingLabel, value: setting.settingId }));
  };

  const onCategoryChange = (categoryID: number): void => {
    setActiveCategory(categoryID);
    setActiveSubCategory(subCategories(categoryID)[0].value as number);
  };

  const onSubCategoryChange = (categoryID: number): void => {
    setActiveSubCategory(categoryID);
  };

  const renderSetting = (): ReactNode => {
    switch (activeSubCategory) {
      case SETTING_ID.SOURCE_TYPE:
        return (
          <UpsertSettings
            type="Source Type"
            key="sourceType"
            isEditable={false}
            upsertSettings={data => _permitSettingsStore.upsertSourceType(data)}
            getSettings={() => _permitSettingsStore.getSourceTypes()}
            settingsData={_permitSettingsStore.sourceTypes}
          />
        );
      case SETTING_ID.RULE_CONDITIONAL_OPERATOR:
        return (
          <UpsertSettings
            type="Rule Conditional Operator"
            key="ruleConditionalOperator"
            getSettings={() => _permitSettingsStore.getRuleConditionalOperators()}
            upsertSettings={data => _permitSettingsStore.upsertRuleConditionalOperators(data)}
            settingsData={_permitSettingsStore.ruleConditionalOperators}
            isEditable={false}
            hideAddNewButton={true}
          />
        );
      case SETTING_ID.RULE_ENTITY:
        return (
          <UpsertSettings
            type="Rule Entity"
            key="ruleEntity"
            getSettings={() => _permitSettingsStore.getRuleEntities()}
            upsertSettings={data => _permitSettingsStore.upsertRuleEntities(data)}
            settingsData={_permitSettingsStore.ruleEntities}
            isEditable={false}
            hideAddNewButton={true}
          />
        );
      case SETTING_ID.BLANKET_VALIDITY:
        return (
          <UpsertSettings
            type="Blanket Validity"
            key="blanketValidity"
            isEditable={false}
            upsertSettings={data => _permitSettingsStore.upsertBlanketValidityType(data)}
            getSettings={() => _permitSettingsStore.getBlanketValidityTypes()}
            settingsData={_permitSettingsStore.blanketValidityTypes}
            regExp={regex.all}
          />
        );
      case SETTING_ID.DOCUMENT:
        return <Document />;
      case SETTING_ID.DIPLOMATIC_CHANNEL_REQUIRED:
        return (
          <UpsertSettings
            type="Diplomatic Channel Required"
            key="diplomaticChannelRequired"
            isEditable={false}
            upsertSettings={data => _permitSettingsStore.upsertPermitDiplomaticType(data)}
            getSettings={() => _permitSettingsStore.getPermitDiplomaticTypes()}
            settingsData={_permitSettingsStore.permitDiplomaticTypes}
            regExp={regex.all}
          />
        );
      case SETTING_ID.ELEMENTS:
        return (
          <UpsertSettings
            type="Elements"
            key="elements"
            isEditable={false}
            upsertSettings={data => _permitSettingsStore.upsertElements(data)}
            getSettings={() => _permitSettingsStore.getElements()}
            settingsData={_permitSettingsStore.elements}
            regExp={regex.all}
          />
        );
      case SETTING_ID.FAR_TYPE:
        return <FARType />;
      case SETTING_ID.FLIGHT_OPERATIONAL_CATEGORY:
        return (
          <UpsertSettings
            type="Flight Operational Category"
            key="flightOperationalCategory"
            upsertSettings={data => _permitSettingsStore.upsertFlightOperationalCategory(data)}
            getSettings={() => _permitSettingsStore.getFlightOperationalCategories()}
            settingsData={_permitSettingsStore.flightOperationalCategories}
            ignoreNumber={true}
            isEditable={false}
          />
        );
      case SETTING_ID.LEAD_TIME_TYPE:
        return (
          <UpsertSettings
            type="Lead Time Type"
            key="leadTimeType"
            isEditable={false}
            upsertSettings={data => _permitSettingsStore.upsertLeadTimeType(data)}
            getSettings={() => _permitSettingsStore.getLeadTimeTypes()}
            settingsData={_permitSettingsStore.leadTimeTypes}
          />
        );
      case SETTING_ID.PERMIT_TYPE:
        return (
          <UpsertSettings
            type="Permit Type"
            key="permitType"
            upsertSettings={data => _permitSettingsStore.upsertPermitType(data)}
            getSettings={() => _permitSettingsStore.getPermitTypes()}
            settingsData={_permitSettingsStore.permitTypes}
            isEditable={false}
          />
        );
      case SETTING_ID.PERMIT_APPLIED_TO:
        return (
          <UpsertSettings
            type="Permit Applied To"
            key="permitAppliedTo"
            upsertSettings={data => _permitSettingsStore.upsertPermitAppliedTo(data)}
            getSettings={() => _permitSettingsStore.getPermitAppliedTo()}
            settingsData={_permitSettingsStore.permitAppliedTo}
            isEditable={false}
          />
        );
      case SETTING_ID.PERMIT_NUMBER_EXCEPTIONS:
        return (
          <UpsertSettings
            type="Permit Number Exceptions"
            key="permitNumberExceptions"
            isEditable={false}
            upsertSettings={data => _permitSettingsStore.upsertPermitNumberException(data)}
            getSettings={() => _permitSettingsStore.getPermitNumberExceptions()}
            settingsData={_permitSettingsStore.permitNumberExceptions}
            regExp={regex.all}
          />
        );
      case SETTING_ID.PERMIT_PREREQUISITES:
        return (
          <UpsertSettings
            type="Permit Prerequisites"
            key="permitPrerequisites"
            isEditable={false}
            upsertSettings={data => _permitSettingsStore.upsertpermitPrerequisiteType(data)}
            getSettings={() => _permitSettingsStore.getpermitPrerequisiteTypes()}
            settingsData={_permitSettingsStore.permitPrerequisiteTypes}
            regExp={regex.all}
          />
        );
      case SETTING_ID.PERMIT_REQUIREMENT_TYPE:
        return (
          <UpsertSettings
            type="Permit Requirement Type"
            key="permitRequirementType"
            upsertSettings={data => _permitSettingsStore.upsertPermitRequirementType(data)}
            getSettings={() => _permitSettingsStore.getPermitRequirementTypes()}
            settingsData={_permitSettingsStore.permitRequirementTypes}
            isEditable={false}
            regExp={regex.all}
          />
        );
      case SETTING_ID.PURPOSE_OF_FLIGHT:
        return (
          <UpsertSettings
            type="Purpose of Flight"
            key="purposeOfFlight"
            upsertSettings={data => _permitSettingsStore.upsertFlightPurpose(data)}
            getSettings={() => _permitSettingsStore.getFlightPurposes()}
            settingsData={_permitSettingsStore.flightPurposes}
            ignoreNumber={true}
            regExp={regex.all}
            isEditable={false}
          />
        );
      case SETTING_ID.TIME_LEVEL_UOM:
        return (
          <UpsertSettings
            type="Time Level UOM"
            key="timeLevelUOM"
            upsertSettings={data => _permitSettingsStore.upsertTimeLevelUOM(data)}
            getSettings={() => _permitSettingsStore.getTimeLevelsUOM()}
            settingsData={_permitSettingsStore.timeLevelsUOM}
            isEditable={false}
            ignoreNumber={true}
          />
        );
      case SETTING_ID.MISSION_ELEMENT:
        return (
          <UpsertSettings
            type="Mission Element"
            key="missionElement"
            upsertSettings={data => _permitSettingsStore.upsertMissionElement(data)}
            getSettings={() => _permitSettingsStore.getMissionElement()}
            settingsData={_permitSettingsStore.missionElements}
            isEditable={false}
            ignoreNumber={true}
          />
        );
      case SETTING_ID.DATA_ELEMENT:
        return (
          <UpsertSettings
            type="Data Element"
            key="dataElement"
            upsertSettings={data => _permitSettingsStore.upsertDataElement(data)}
            getSettings={() => _permitSettingsStore.getDataElement()}
            settingsData={_permitSettingsStore.dataElements}
            isEditable={false}
            ignoreNumber={true}
          />
        );

      case SETTING_ID.CROSSING_TYPE:
        return (
          <UpsertSettings
            type="Crossing Type"
            key="crossingType"
            upsertSettings={data => _permitSettingsStore.upsertCrossingType(data)}
            getSettings={() => _permitSettingsStore.getCrossingType()}
            settingsData={_permitSettingsStore.crossingTypes}
            isEditable={false}
            ignoreNumber={true}
            hideAddNewButton={true}
          />
        );
      case SETTING_ID.PERMIT_CLASSIFICATION:
        return (
          <UpsertSettings
            type="Permit Classification"
            key="permitClassification"
            upsertSettings={data => _permitSettingsStore.upsertPermitClassification(data)}
            getSettings={() => _permitSettingsStore.getPermitClassifications()}
            settingsData={_permitSettingsStore.permitClassifications}
            isEditable={false}
          />
        );

      default:
      case SETTING_ID.ACCESS_LEVEL:
        return (
          <UpsertSettings
            type="Access Level"
            key="accessLevel"
            isEditable={false}
            upsertSettings={data => _permitSettingsStore.upsertAccessLevel(data)}
            getSettings={() => _permitSettingsStore.getAccessLevels()}
            settingsData={_permitSettingsStore.accessLevels}
          />
        );
    }
  };

  return (
    <SettingsLayout
      title="Permit"
      categoryValue={activeCategory}
      subCategoryValue={activeSubCategory}
      children={renderSetting()}
      categoryList={categoryList}
      subCategoryList={subCategories()}
      onCategoryChange={id => onCategoryChange(id)}
      onSubCategoryChange={id => onSubCategoryChange(id)}
    />
  );
};

export default inject('permitSettingsStore')(observer(Settings));
export { Settings as PureSettings };
